import { useEffect, useState } from "react"
import "src/sections/capterra/Pricing.scss"
import SwiperCore, { Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper-bundle.css"
import Button from "../../components/Form/Button"
import CheckmarkSVG from "./images/check.svg"
import DashSVG from "./images/dash.svg"
import "./Pricing.scss"
import { Link, useNavigate } from 'react-router-dom'
const Checkmark = () => (
  <div style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "8px" }}>
    <img src={CheckmarkSVG} alt='Checkmark' />
  </div>
)

const Dash = () => (
  <div style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "8px" }}>
    <img src={DashSVG} alt='Dash' />
  </div>
)

const Pricing = () => {
  const [isAnnual, setIsAnnual] = useState<boolean>(false)
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth)
  const [activeSlide, setActiveSlide] = useState<number>(0) // State to track active slide
  const navigate = useNavigate()
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  SwiperCore.use([Pagination])

  const togglePaymentPeriod = () => {
    setIsAnnual(!isAnnual)
  }

  return (
    <>
      <section className='prices-desktop'>
        <div className='pricing-head pricing-head-capterra' role='presentation'>
          <div className='ornament-block'></div>
          <div className='container'>
            <div className='pricing-head__wrapper'>
              <h1 className='pricing-head__title main-title'>Pricing</h1>
              <h4 className='pricing-second-title'>Choose the plan that’s right for your business</h4>
              <div className='pricing-head-block'>
                <div className='pricing-head-block'>
                  <div className='pricing-head-plan'>
                    <div className='pricing-head-plan__title'>
                      <h5 className='pricing-head-plan__name section-tertiary-title'>LOUNGE</h5>
                    </div>
                    <p className='pricing-head-plan__subtitle'>
                      *One Time Setup and Configuration Fee Starting at $7,200{" "}
                    </p>
                    <p className='pricing-head-plan__price'>
                      <span className='pricing-head-plan__price-amount section-secondary-title'>
                        {isAnnual ? "$675" : "$750"}
                      </span>

                      <span className='pricing-head-plan__price-period'>{isAnnual ? "/mo" : "/mo"}</span>
                    </p>
                    <span className='pricing-head-plan_billing'>
                      Billed{" "}
                      {isAnnual ? (
                        <>
                          at <s>$9,000</s> $8,100/yr
                        </>
                      ) : (
                        "Monthly"
                      )}
                    </span>

                    <div className='toggle-switch' style={{ width: 250 }} onClick={togglePaymentPeriod}>
                      <span
                        className={`monthly-text ${isAnnual ? "gray-text" : "white-text"}`}
                        style={{
                          position: "absolute",
                          left: "20px",
                          fontSize: "12px",
                          display: "flex",
                          alignItems: "center",
                          height: "100%",
                          textAlign: "center",
                          zIndex: 1,
                        }}
                      >
                        Pay Monthly<br></br>
                        Commit Annually
                      </span>

                      <span
                        className={`annual-text ${!isAnnual ? "darkgreen" : ""}`}
                        style={{
                          position: "absolute",
                          right: "20px",
                          fontSize: "12px",
                          color: "#fff",
                          display: "flex",
                          alignItems: "center",
                          height: "100%",
                          textAlign: "center",
                          zIndex: 1,
                        }}
                      >
                        {isAnnual ? "Pay Upfront" : "Pay Upfront"}
                        <br />
                        {isAnnual ? "SAVE  " : "SAVE  "}
                        <br />
                        <span>{isAnnual ? "Commit Annually" : "Commit Annually"}</span>
                      </span>

                      <div className={`toggle-handle ${isAnnual ? "annual" : "monthly"}`} />
                    </div>

                    <p className='pricing-head-plan__text'>
                      Designed for hospitality brands starting with subscription
                    </p>
                    <ul className='pricing-head-plan__list'>
                      <p>This plan is befitting for any restaurant looking to offer subscriptions</p>
                      <br></br>
                      <p>Enroll your guests with your branded online application and manage them in a CRM</p>
                      <br></br>
                      <p>
                        Allow your guests to add a card on file to pay their subscription and manage their account
                        (preferences and billing) in a member app
                      </p>
                    </ul>
                  </div>

                  <div className='pricing-head-plan'>
                    <div className='pricing-head-plan__title'>
                      <h5 className='pricing-head-plan__name section-tertiary-title'>CLUB</h5>
                    </div>
                    <p className='pricing-head-plan__subtitle'>
                      *One Time Setup and Configuration Fee Starting at $7,200{" "}
                    </p>
                    <p className='pricing-head-plan__price'>
                      <span className='pricing-head-plan__price-amount section-secondary-title'><a className="contact-sales" href="mailto:sales@peoplevine.com?subject=Club%20Plan%20Pricing%20Inquiry">
                        {isAnnual ? "Contact Sales" : "Contact Sales"}</a>
                      </span>

                      <span className='pricing-head-plan__price-period'>{isAnnual ? "" : ""}</span>
                    </p>
                    <span className='pricing-head-plan_billing'></span>
                    <div className='toggle-switch' style={{ width: 250 }} onClick={togglePaymentPeriod}>
                      <span
                        className={`monthly-text ${isAnnual ? "gray-text" : "white-text"}`}
                        style={{
                          position: "absolute",
                          left: "20px",
                          fontSize: "12px",
                          display: "flex",
                          alignItems: "center",
                          height: "100%",
                          textAlign: "center",
                          zIndex: 1,
                        }}
                      >
                        Pay Monthly<br></br>
                        Commit Annually
                      </span>

                      <span
                        className={`annual-text ${!isAnnual ? "darkgreen" : ""}`}
                        style={{
                          position: "absolute",
                          right: "20px",
                          fontSize: "12px",
                          color: "#fff",
                          display: "flex",
                          alignItems: "center",
                          height: "100%",
                          textAlign: "center",
                          zIndex: 1,
                        }}
                      >
                        {isAnnual ? "Pay Upfront" : "Pay Upfront"}
                        <br />
                        {isAnnual ? "SAVE  " : "SAVE  "}
                        <br />
                        <span>{isAnnual ? "Commit Annually" : "Commit Annually"}</span>
                      </span>

                      <div className={`toggle-handle ${isAnnual ? "annual" : "monthly"}`} />
                    </div>

                    <p className='pricing-head-plan__text'>
                      Designed for restaurants and private clubs with memberships
                    </p>
                    <ul className='pricing-head-plan__list'>
                      <p>Everything in the Lounge Plan, plus</p>
                      <br></br>
                      <p>Allow staff to recognize guest preferences and and apply member perks</p>
                      <br></br>
                      <p>Give your members full access to your services and amenities in the palm of their hand</p>
                      <br></br>
                      <p>Allow your members to book dining, events, workspaces and activities</p>
                    </ul>
                  </div>

                  <div className='pricing-head-plan'>
                    <div className='pricing-head-plan__title'>
                      <h5 className='pricing-head-plan__name section-tertiary-title'>HOUSE</h5>
                    </div>
                    <p className='pricing-head-plan__subtitle'>
                      *One Time Setup and Configuration Fee Starting at $7,200{" "}
                    </p>
                    <p className='pricing-head-plan__price'>
                      <span className='pricing-head-plan__price-amount section-secondary-title'><a className="contact-sales" href="mailto:sales@peoplevine.com?subject=House%20Plan%20Pricing%20Inquiry">
                        {isAnnual ? "Contact Sales" : "Contact Sales"}</a>
                      </span>

                      <span className='pricing-head-plan__price-period'>{isAnnual ? "" : ""}</span>
                    </p>
                    <span className='pricing-head-plan_billing'></span>

                    <div className='toggle-switch' style={{ width: 250 }} onClick={togglePaymentPeriod}>
                      <span
                        className={`monthly-text ${isAnnual ? "gray-text" : "white-text"}`}
                        style={{
                          position: "absolute",
                          left: "20px",
                          fontSize: "12px",
                          display: "flex",
                          alignItems: "center",
                          height: "100%",
                          textAlign: "center",
                          zIndex: 1,
                        }}
                      >
                        Pay Monthly<br></br>
                        Commit Annually
                      </span>

                      <span
                        className={`annual-text ${!isAnnual ? "darkgreen" : ""}`}
                        style={{
                          position: "absolute",
                          right: "20px",
                          fontSize: "12px",
                          color: "#fff",
                          display: "flex",
                          alignItems: "center",
                          height: "100%",
                          textAlign: "center",
                          zIndex: 1,
                        }}
                      >
                        {isAnnual ? "Pay Upfront" : "Pay Upfront"}
                        <br />
                        {isAnnual ? "SAVE  " : "SAVE  "}
                        <br />
                        <span>{isAnnual ? "Commit Annually" : "Commit Annually"}</span>
                      </span>

                      {/* Handle */}
                      <div className={`toggle-handle ${isAnnual ? "annual" : "monthly"}`} />
                    </div>

                    <p className='pricing-head-plan__text'>Designed with the luxury hotel in mind</p>
                    <ul className='pricing-head-plan__list'>
                      <p>Everything in the Club Plan, plus</p>
                      <br></br>
                      <p>
                        Recognize your members across your multiple locations and allow them to pay with a global card
                        on file.
                      </p>
                      <br></br>
                      <p>Give your members full access to your services and amenities in the palm of their hand</p>
                      <br></br>
                      <p>Allow your members to book rooms and spa services and wellness classes</p>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "50px",
                textAlign: "center",
              }}
            >
              <Button
                className='button-request'
                onClick={() => {
                  navigate('/form')
                  // Handle click event for request demo
                  console.log("DEMO RQ BUTTON")
                }}
              >
                Request a demo
              </Button>
            </div>
          </div>
        </div>
      </section>
      {/* Desktop Table */}
      <section className='desktop-table'>
        <div style={{ backgroundColor: "#FFFFFF", paddingTop: "30px" }}>
          <div className='table-container'>
            <table>
              <thead className='table-head-desktop'>
                <tr>
                  <th
                    colSpan={1}
                    style={{ borderLeft: "none", borderRight: "none", borderTop: "none", paddingBottom: "30px" }}
                  >
                    <h2> FEATURES</h2>
                  </th>
                  <th
                    colSpan={1}
                    style={{ borderLeft: "none", borderRight: "none", borderTop: "none", paddingBottom: "30px" }}
                  >
                    <h2>LOUNGE</h2>
                    <span className='price-table-plan'>
                      {isAnnual ? "$8,100" : "$750"}
                      <span style={{ textTransform: "none" }} className='pricing-head-plan__price-period'>
                        {isAnnual ? "/yr" : "/mo"}
                      </span>
                    </span>
                  </th>
                  <th
                    colSpan={1}
                    style={{ borderLeft: "none", borderRight: "none", borderTop: "none", paddingBottom: "30px" }}
                  >
                    <h2>CLUB</h2>
                    <span className='price-table-plan'><a className="contact-sales-table" href="mailto:sales@peoplevine.com?subject=Club%20Plan%20Pricing%20Inquiry">
                      {isAnnual ? "CONTACT SALES" : "CONTACT SALES"}</a>
                      <span style={{ textTransform: "none" }} className='pricing-head-plan__price-period'>
                        {isAnnual ? "" : ""}
                      </span>
                    </span>
                  </th>
                  <th
                    colSpan={1}
                    style={{ borderLeft: "none", borderRight: "none", borderTop: "none", paddingBottom: "30px" }}
                  >
                    <h2>HOUSE</h2>
                    <span className='price-table-plan'><a className="contact-sales-table" href="mailto:sales@peoplevine.com?subject=House%20Plan%20Pricing%20Inquiry">
                      {isAnnual ? "CONTACT SALES" : "CONTACT SALES"}</a>
                      <span style={{ textTransform: "none" }} className='pricing-head-plan__price-period'>
                        {isAnnual ? "" : ""}
                      </span>
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ width: "25%" }}>CRM w/Unlimited Contacts & Users</td>
                  <td style={{ width: "25%" }}>
                    <Checkmark />
                  </td>
                  <td style={{ width: "25%" }}>
                    <Checkmark />
                  </td>
                  <td style={{ width: "25%" }}>
                    <Checkmark />
                  </td>
                </tr>

                <tr>
                  <td style={{ width: "25%" }}>Signup Form w/Card on file Authorization</td>
                  <td style={{ width: "25%" }}>
                    <Checkmark />
                  </td>
                  <td style={{ width: "25%" }}>
                    <Checkmark />
                  </td>
                  <td style={{ width: "25%" }}>
                    <Checkmark />
                  </td>
                </tr>
                <tr>
                  <td style={{ width: "25%" }}>Email/Newsletter, Marketing Automations, SMS</td>
                  <td style={{ width: "25%" }}>
                    <Checkmark />
                  </td>
                  <td style={{ width: "25%" }}>
                    <Checkmark />
                  </td>
                  <td style={{ width: "25%" }}>
                    <Checkmark />
                  </td>
                </tr>
                <tr>
                  <td style={{ width: "25%" }}>Member & Guest Check-in App</td>
                  <td style={{ width: "25%" }}>
                    <Checkmark />
                  </td>
                  <td style={{ width: "25%" }}>
                    <Checkmark />
                  </td>
                  <td style={{ width: "25%" }}>
                    <Checkmark />
                  </td>
                </tr>
                <tr>
                  <td style={{ width: "25%" }}>Digital ID Card</td>
                  <td style={{ width: "25%" }}>
                    <Checkmark />
                  </td>
                  <td style={{ width: "25%" }}>
                    <Checkmark />
                  </td>
                  <td style={{ width: "25%" }}>
                    <Checkmark />
                  </td>
                </tr>
                <tr>
                  <td style={{ width: "25%" }}>Close POS Check to card On File</td>
                  <td style={{ width: "25%" }}>
                    <Checkmark />
                  </td>
                  <td style={{ width: "25%" }}>
                    <Checkmark />
                  </td>
                  <td style={{ width: "25%" }}>
                    <Checkmark />
                  </td>
                </tr>
                <tr>
                  <td style={{ width: "25%" }}>Membership Application</td>
                  <td style={{ width: "25%" }}>
                    <Checkmark />
                  </td>
                  <td style={{ width: "25%" }}>
                    <Checkmark />
                  </td>
                  <td style={{ width: "25%" }}>
                    <Checkmark />
                  </td>
                </tr>
                <tr>
                  <td style={{ width: "25%" }}>
                    My Account, Profile & Pref, Digital Wallet, House Credits & Payments, Billing & Statements,
                    Invoices, Digital ID Card
                  </td>
                  <td style={{ width: "25%" }}>
                    <Checkmark />
                  </td>
                  <td style={{ width: "25%" }}>
                    <Checkmark />
                  </td>
                  <td style={{ width: "25%" }}>
                    <Checkmark />
                  </td>
                </tr>
                <tr>
                  <td style={{ width: "25%" }}>Membership Perks</td>
                  <td style={{ width: "25%" }}>
                    <Checkmark />
                  </td>
                  <td style={{ width: "25%" }}>
                    <Checkmark />
                  </td>
                  <td style={{ width: "25%" }}>
                    <Checkmark />
                  </td>
                </tr>

                <tr>
                  <td style={{ width: "25%" }}>Dinning Reservations</td>
                  <td style={{ width: "25%" }}>
                    <Dash />
                  </td>
                  <td style={{ width: "25%" }}>
                    <Checkmark />
                  </td>
                  <td style={{ width: "25%" }}>
                    <Checkmark />
                  </td>
                </tr>
                <tr>
                  <td style={{ width: "25%" }}>Events & Ticketing</td>
                  <td style={{ width: "25%" }}>
                    <Dash />
                  </td>
                  <td style={{ width: "25%" }}>
                    <Checkmark />
                  </td>
                  <td style={{ width: "25%" }}>
                    <Checkmark />
                  </td>
                </tr>
                <tr>
                  <td style={{ width: "25%" }}>In-App Messaging</td>
                  <td style={{ width: "25%" }}>
                    <Dash />
                  </td>
                  <td style={{ width: "25%" }}>
                    <Checkmark />
                  </td>
                  <td style={{ width: "25%" }}>
                    <Checkmark />
                  </td>
                </tr>
                <tr>
                  <td style={{ width: "25%" }}>Staff Directory</td>
                  <td style={{ width: "25%" }}>
                    <Dash />
                  </td>
                  <td style={{ width: "25%" }}>
                    <Checkmark />
                  </td>
                  <td style={{ width: "25%" }}>
                    <Checkmark />
                  </td>
                </tr>
                <tr>
                  <td style={{ width: "25%" }}>Dedicated Account Manager</td>
                  <td style={{ width: "25%" }}>
                    <Dash />
                  </td>
                  <td style={{ width: "25%" }}>
                    <Checkmark />
                  </td>
                  <td style={{ width: "25%" }}>
                    <Checkmark />
                  </td>
                </tr>
                <tr>
                  <td style={{ width: "25%" }}></td>
                  <td style={{ width: "25%" }}></td>
                  <td style={{ width: "25%" }}></td>
                  <td style={{ width: "25%" }}></td>
                </tr>
                <tr>
                  <td style={{ width: "25%" }}></td>
                  <td style={{ width: "25%" }}></td>
                  <td style={{ width: "25%" }}></td>
                  <td style={{ width: "25%" }}></td>
                </tr>
                <tr>
                  <td style={{ width: "25%", backgroundColor: "#57806E33" }}>
                    <b>EXPERIENCE SPECIALTY MODULES</b>
                  </td>
                  <td style={{ width: "25%", backgroundColor: "#57806E33" }}></td>
                  <td style={{ width: "25%", backgroundColor: "#57806E33" }}></td>
                  <td style={{ width: "25%", backgroundColor: "#57806E33" }}></td>
                </tr>

                <tr className='no-border-td'>
                  <td style={{ width: "25%" }}>Appointment Bookings</td>
                  <td style={{ width: "25%" }}></td>
                  <td style={{ width: "25%" }}></td>
                  <td style={{ width: "25%" }}></td>
                </tr>
                <tr className='no-border-td'>
                  <td style={{ width: "25%" }}>Spa & Wellness Bookings</td>
                  <td style={{ width: "25%" }}></td>
                  <td style={{ width: "25%" }}></td>
                  <td style={{ width: "25%" }}></td>
                </tr>
                <tr className='no-border-td'>
                  <td style={{ width: "25%" }}>Activity Reservations</td>
                  <td style={{ width: "25%" }}></td>
                  <td style={{ width: "25%" }}></td>
                  <td style={{ width: "25%" }}></td>
                </tr>
                <tr className='no-border-td'>
                  <td style={{ width: "25%" }}>Amenity or Workspace Bookings</td>
                  <td style={{ width: "25%" }}>N/A</td>
                  <td style={{ width: "25%" }}>Up to 2</td>
                  <td style={{ width: "25%" }}>Up to 4</td>
                </tr>
                <tr className='no-border-td'>
                  <td style={{ width: "25%" }}></td>
                  <td style={{ width: "25%" }}></td>
                  <td style={{ width: "25%" }}></td>
                  <td style={{ width: "25%" }}></td>
                </tr>
                <tr className='no-border-td'>
                  <td style={{ width: "25%" }}>Global Payments (Requires House Plan+)</td>
                  <td style={{ width: "25%" }}></td>
                  <td style={{ width: "25%" }}></td>
                  <td style={{ width: "25%" }}></td>
                </tr>
                <tr className='no-border-td'>
                  <td style={{ width: "25%" }}>Hotel Booking Sync (Requires house Plan+)</td>
                  <td style={{ width: "25%" }}></td>
                  <td style={{ width: "25%" }}></td>
                  <td style={{ width: "25%" }}></td>
                </tr>
                <tr className='no-border-td-end'>
                  <td style={{ width: "25%" }}>Branded Residences (Requires House Plan+)</td>
                  <td style={{ width: "25%" }}></td>
                  <td style={{ width: "25%" }}></td>
                  <td style={{ width: "25%" }}></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>
      
      {/*MOBILE SECTIIONS */}
      <section className='prices-mobile'>
        <div className='pricing-head pricing-head-capterra' role='presentation'>
          <div className=''>
            <div className=''>
              <h1 className='pricing-head__title main-title'>Pricing</h1>
              <h4 className='pricing-second-title'>Choose the plan that’s right for your business</h4>
              <Swiper
                slidesPerView={1}
                spaceBetween={8}
                initialSlide={0}
                centeredSlides={true}
                pagination={{
                  clickable: true,
                }}
                onSlideChange={swiper => setActiveSlide(swiper.activeIndex)} // Update active slide index
                autoHeight
              >
                <SwiperSlide>
                  <div className='pricing-head-plan'>
                    <div className='pricing-head-plan__title'>
                      <h5 className='pricing-head-plan__name section-tertiary-title'>LOUNGE</h5>
                    </div>
                    <p className='pricing-head-plan__subtitle'>
                      *One Time Setup and Configuration Fee Starting at $7,200{" "}
                    </p>
                    <p className='pricing-head-plan__price'>
                      <span className='pricing-head-plan__price-amount section-secondary-title'>
                        {isAnnual ? "$675" : "$750"}
                      </span>
                      <span className='pricing-head-plan__price-period'>{isAnnual ? "/mo" : "/mo"}</span>
                    </p>
                    <span className='pricing-head-plan_billing'>Billed {isAnnual ? (
                        <>
                          at <s>$9,000</s> $8,100/yr
                        </>
                      ) : (
                        "Monthly"
                      )}</span>
                    <div className='toggle-switch' style={{ width: 250 }} onClick={togglePaymentPeriod}>
                      <span
                        className={`monthly-text ${isAnnual ? "gray-text" : "white-text"}`}
                        style={{
                          position: "absolute",
                          left: "20px",
                          fontSize: "12px",
                          display: "flex",
                          alignItems: "center",
                          height: "100%",
                          textAlign: "center",
                          zIndex: 1,
                        }}
                      >
                        Pay Monthly
                        <br />
                        Commit Annually
                      </span>
                      <span
                        className={`annual-text ${!isAnnual ? "darkgreen" : ""}`}
                        style={{
                          position: "absolute",
                          right: "20px",
                          fontSize: "12px",
                          color: "#fff",
                          display: "flex",
                          alignItems: "center",
                          height: "100%",
                          textAlign: "center",
                          zIndex: 1,
                        }}
                      >
                        {isAnnual ? "Pay Upfront" : "Pay Upfront"}
                        <br />
                        {isAnnual ? "SAVE  " : "SAVE  "}
                        <br />
                        <span>{isAnnual ? "Commit Annually" : "Commit Annually"}</span>
                      </span>
                      <div className={`toggle-handle ${isAnnual ? "annual" : "monthly"}`} />
                    </div>
                    <p className='pricing-head-plan__text'>
                      Designed for hospitality brands starting with subscription
                    </p>
                    <ul className='pricing-head-plan__list'>
                      <p>This plan is befitting for any restaurant looking to offer subscriptions</p>
                      <br />
                      <p>Enroll your guests with your branded online application and manage them in a CRM</p>
                      <br />
                      <p>
                        Allow your guests to add a card on file to pay their subscription and manage their account
                        (preferences and billing) in a member app
                      </p>
                    </ul>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className='pricing-head-plan'>
                    <div className='pricing-head-plan__title'>
                      <h5 className='pricing-head-plan__name section-tertiary-title'>CLUB</h5>
                    </div>
                    <p className='pricing-head-plan__subtitle'>
                      *One Time Setup and Configuration Fee Starting at $7,200{" "}
                    </p>
                    <p className='pricing-head-plan__price'>
                      <span className='pricing-head-plan__price-amount section-secondary-title'><a className="contact-sales" href="mailto:sales@peoplevine.com?subject=Club%20Plan%20Pricing%20Inquiry">
                        {isAnnual ? "Contact Sales" : "Contact Sales"}</a>
                      </span>
                      <span className='pricing-head-plan__price-period'>{isAnnual ? "" : ""}</span>
                    </p>
                    <span className='pricing-head-plan_billing'></span>
                    <div className='toggle-switch' style={{ width: 250 }} onClick={togglePaymentPeriod}>
                      <span
                        className={`monthly-text ${isAnnual ? "gray-text" : "white-text"}`}
                        style={{
                          position: "absolute",
                          left: "20px",
                          fontSize: "12px",
                          display: "flex",
                          alignItems: "center",
                          height: "100%",
                          textAlign: "center",
                          zIndex: 1,
                        }}
                      >
                        Pay Monthly
                        <br />
                        Commit Annually
                      </span>
                      <span
                        className={`annual-text ${!isAnnual ? "darkgreen" : ""}`}
                        style={{
                          position: "absolute",
                          right: "20px",
                          fontSize: "12px",
                          color: "#fff",
                          display: "flex",
                          alignItems: "center",
                          height: "100%",
                          textAlign: "center",
                          zIndex: 1,
                        }}
                      >
                        {isAnnual ? "Pay Upfront" : "Pay Upfront"}
                        <br />
                        {isAnnual ? "SAVE  " : "SAVE  "}
                        <br />
                        <span>{isAnnual ? "Commit Annually" : "Commit Annually"}</span>
                      </span>
                      <div className={`toggle-handle ${isAnnual ? "annual" : "monthly"}`} />
                    </div>
                    <p className='pricing-head-plan__text'>
                      Designed for restaurants and private clubs with memberships
                    </p>
                    <ul className='pricing-head-plan__list'>
                      <p>Everything in the Lounge Plan, plus</p>
                      <br />
                      <p>Allow staff to recognize guest preferences and apply member perks</p>
                      <br />
                      <p>Give your members full access to your services and amenities in the palm of their hand</p>
                      <br />
                      <p>Allow your members to book dining, events, workspaces and activities</p>
                    </ul>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className='pricing-head-plan'>
                    <div className='pricing-head-plan__title'>
                      <h5 className='pricing-head-plan__name section-tertiary-title'>HOUSE</h5>
                    </div>
                    <p className='pricing-head-plan__subtitle'>
                      *One Time Setup and Configuration Fee Starting at $7,200{" "}
                    </p>
                    <p className='pricing-head-plan__price'>
                      <span className='pricing-head-plan__price-amount section-secondary-title'><a className="contact-sales" href="mailto:sales@peoplevine.com?subject=House%20Plan%20Pricing%20Inquiry">
                        {isAnnual ? "Contact Sales" : "Contact Sales"}</a>
                      </span>
                      <span className='pricing-head-plan__price-period'>{isAnnual ? "" : ""}</span>
                    </p>
                    <span className='pricing-head-plan_billing'></span>
                    <div className='toggle-switch' style={{ width: 250 }} onClick={togglePaymentPeriod}>
                      <span
                        className={`monthly-text ${isAnnual ? "gray-text" : "white-text"}`}
                        style={{
                          position: "absolute",
                          left: "20px",
                          fontSize: "12px",
                          display: "flex",
                          alignItems: "center",
                          height: "100%",
                          textAlign: "center",
                          zIndex: 1,
                        }}
                      >
                        Pay Monthly
                        <br />
                        Commit Annually
                      </span>
                      <span
                        className={`annual-text ${!isAnnual ? "darkgreen" : ""}`}
                        style={{
                          position: "absolute",
                          right: "20px",
                          fontSize: "12px",
                          color: "#fff",
                          display: "flex",
                          alignItems: "center",
                          height: "100%",
                          textAlign: "center",
                          zIndex: 1,
                        }}
                      >
                        {isAnnual ? "Pay Upfront" : "Pay Upfront"}
                        <br />
                        {isAnnual ? "SAVE  " : "SAVE  "}
                        <br />
                        <span>{isAnnual ? "Commit Annually" : "Commit Annually"}</span>
                      </span>
                      <div className={`toggle-handle ${isAnnual ? "annual" : "monthly"}`} />
                    </div>
                    <p className='pricing-head-plan__text'>
                      Designed for restaurants and private clubs with memberships
                    </p>
                    <ul className='pricing-head-plan__list'>
                      <p>Everything in the Lounge and Club Plan, plus</p>
                      <br />
                      <p>Customize branded app experience</p>
                      <br />
                      <p>Enjoy custom integrations and additional support</p>
                    </ul>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
        <div style={{ marginTop: "30px" }}>
          {activeSlide === 0 && (
            <section className='lounge-table'>
              <section style={{ backgroundColor: "#FFFFFF", paddingTop: "30px" }}>
                <div className='table-container'>
                  <table>
                    <thead>
                      <tr>
                        {/*
                        <th
                          colSpan={1}
                          style={{ borderLeft: "none", borderRight: "none", borderTop: "none", paddingBottom: "40px" }}
                        >
                          <h2> FEATURES</h2>
                        </th>
                        */}
                        <th
                          colSpan={2}
                          style={{ borderLeft: "none", borderRight: "none", borderTop: "none", paddingBottom: "40px" }}
                        >
                          <h2>LOUNGE</h2>
                          <span className='price-table-plan'>
                            {isAnnual ? "$8,100" : "$750"}
                            <span style={{ textTransform: "none" }} className='pricing-head-plan__price-period'>
                              {isAnnual ? "/yr" : "/mo"}
                            </span>
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td style={{ width: "25%" }}>CRM w/Unlimited Contacts & Users</td>
                        <td style={{ width: "25%" }}>
                          <Checkmark />
                        </td>
                      </tr>

                      <tr>
                        <td style={{ width: "25%" }}>Signup Form w/Card on file Authorization</td>
                        <td style={{ width: "25%" }}>
                          <Checkmark />
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "25%" }}>Email/Newsletter, Marketing Automations, SMS</td>
                        <td style={{ width: "25%" }}>
                          <Checkmark />
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "25%" }}>Member & Guest Check-in App</td>
                        <td style={{ width: "25%" }}>
                          <Checkmark />
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "25%" }}>Digital ID Card</td>
                        <td style={{ width: "25%" }}>
                          <Checkmark />
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "25%" }}>Close POS Check to card On File</td>
                        <td style={{ width: "25%" }}>
                          <Checkmark />
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "25%" }}>Membership Application</td>
                        <td style={{ width: "25%" }}>
                          <Checkmark />
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "25%" }}>
                          My Account, Profile & Pref, Digital Wallet, House Credits & Payments, Billing & Statements,
                          Invoices, Digital ID Card
                        </td>
                        <td style={{ width: "25%" }}>
                          <Checkmark />
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "25%" }}>Membership Perks</td>
                        <td style={{ width: "25%" }}>
                          <Checkmark />
                        </td>
                      </tr>

                      <tr>
                        <td style={{ width: "25%" }}>Dinning Reservations</td>
                        <td style={{ width: "25%" }}>
                          <Dash />
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "25%" }}>Events & Ticketing</td>
                        <td style={{ width: "25%" }}>
                          <Dash />
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "25%" }}>In-App Messaging</td>
                        <td style={{ width: "25%" }}>
                          <Dash />
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "25%" }}>Staff Directory</td>
                        <td style={{ width: "25%" }}>
                          <Dash />
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "25%" }}>Dedicated Account Manager</td>
                        <td style={{ width: "25%" }}>
                          <Dash />
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "25%" }}></td>
                        <td style={{ width: "25%" }}></td>
                      </tr>
                      <tr>
                        <td style={{ width: "25%" }}></td>
                        <td style={{ width: "25%" }}></td>
                      </tr>
                      <tr>
                        <td style={{ width: "25%", backgroundColor: "#57806E33" }}>
                          <b>EXPERIENCE SPECIALTY MODULES</b>
                        </td>
                        <td style={{ width: "25%", backgroundColor: "#57806E33" }}></td>
                      </tr>

                      <tr className='no-border-td'>
                        <td style={{ width: "25%" }}>Appointment Bookings</td>
                        <td style={{ width: "25%" }}></td>
                      </tr>
                      <tr className='no-border-td'>
                        <td style={{ width: "25%" }}>Spa & Wellness Bookings</td>
                        <td style={{ width: "25%" }}></td>
                      </tr>
                      <tr className='no-border-td'>
                        <td style={{ width: "25%" }}>Activity Reservations</td>
                        <td style={{ width: "25%" }}></td>
                      </tr>
                      <tr className='no-border-td'>
                        <td style={{ width: "25%" }}>Amenity or Workspace Bookings</td>
                        <td style={{ width: "25%" }}>N/A</td>
                      </tr>
                      <tr className='no-border-td'>
                        <td style={{ width: "25%" }}></td>
                        <td style={{ width: "25%" }}></td>
                      </tr>
                      <tr className='no-border-td'>
                        <td style={{ width: "25%" }}>Global Payments (Requires House Plan+)</td>
                        <td style={{ width: "25%" }}></td>
                      </tr>
                      <tr className='no-border-td'>
                        <td style={{ width: "25%" }}>Hotel Booking Sync (Requires house Plan+)</td>
                        <td style={{ width: "25%" }}></td>
                      </tr>
                      <tr className='no-border-td-end'>
                        <td style={{ width: "25%" }}>Branded Residences (Requires House Plan+)</td>
                        <td style={{ width: "25%" }}></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </section>
            </section>
          )}
          {activeSlide === 1 && (
            <section className='club-table'>
              <section style={{ backgroundColor: "#FFFFFF" }}>
                <div className='table-container'>
                  <table>
                    <thead>
                      <tr>
                        {/* <th
                          colSpan={1}
                          style={{ borderLeft: "none", borderRight: "none", borderTop: "none", paddingBottom: "30px" }}
                        >
                          <h2> FEATURES</h2>
                        </th> */}

                        <th
                          colSpan={2}
                          style={{ borderLeft: "none", borderRight: "none", borderTop: "none", paddingBottom: "30px" }}
                        >
                          <h2>CLUB</h2>
                          <span className='price-table-plan'><a className="contact-sales-table" href="mailto:sales@peoplevine.com?subject=Club%20Plan%20Pricing%20Inquiry">
                            {isAnnual ? "CONTACT SALES" : "CONTACT SALES"}</a>
                            <span style={{ textTransform: "none" }} className='pricing-head-plan__price-period'>
                              {isAnnual ? "" : ""}
                            </span>
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td style={{ width: "25%" }}>CRM w/Unlimited Contacts & Users</td>
                        <td style={{ width: "25%" }}>
                          <Checkmark />
                        </td>
                      </tr>

                      <tr>
                        <td style={{ width: "25%" }}>Signup Form w/Card on file Authorization</td>
                        <td style={{ width: "25%" }}>
                          <Checkmark />
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "25%" }}>Email/Newsletter, Marketing Automations, SMS</td>
                        <td style={{ width: "25%" }}>
                          <Checkmark />
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "25%" }}>Member & Guest Check-in App</td>
                        <td style={{ width: "25%" }}>
                          <Checkmark />
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "25%" }}>Digital ID Card</td>
                        <td style={{ width: "25%" }}>
                          <Checkmark />
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "25%" }}>Close POS Check to card On File</td>
                        <td style={{ width: "25%" }}>
                          <Checkmark />
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "25%" }}>Membership Application</td>
                        <td style={{ width: "25%" }}>
                          <Checkmark />
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "25%" }}>
                          My Account, Profile & Pref, Digital Wallet, House Credits & Payments, Billing & Statements,
                          Invoices, Digital ID Card
                        </td>
                        <td style={{ width: "25%" }}>
                          <Checkmark />
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "25%" }}>Membership Perks</td>
                        <td style={{ width: "25%" }}>
                          <Checkmark />
                        </td>
                      </tr>

                      <tr>
                        <td style={{ width: "25%" }}>Dinning Reservations</td>

                        <td style={{ width: "25%" }}>
                          <Checkmark />
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "25%" }}>Events & Ticketing</td>

                        <td style={{ width: "25%" }}>
                          <Checkmark />
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "25%" }}>In-App Messaging</td>

                        <td style={{ width: "25%" }}>
                          <Checkmark />
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "25%" }}>Staff Directory</td>

                        <td style={{ width: "25%" }}>
                          <Checkmark />
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "25%" }}>Dedicated Account Manager</td>

                        <td style={{ width: "25%" }}>
                          <Checkmark />
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "25%" }}></td>

                        <td style={{ width: "25%" }}></td>
                      </tr>
                      <tr>
                        <td style={{ width: "25%" }}></td>

                        <td style={{ width: "25%" }}></td>
                      </tr>
                      <tr>
                        <td style={{ width: "25%", backgroundColor: "#57806E33" }}>
                          <b>EXPERIENCE SPECIALTY MODULES</b>
                        </td>
                        <td style={{ width: "25%", backgroundColor: "#57806E33" }}></td>
                      </tr>

                      <tr className='no-border-td'>
                        <td style={{ width: "25%" }}>Appointment Bookings</td>

                        <td style={{ width: "25%" }}></td>
                      </tr>
                      <tr className='no-border-td'>
                        <td style={{ width: "25%" }}>Spa & Wellness Bookings</td>

                        <td style={{ width: "25%" }}></td>
                      </tr>
                      <tr className='no-border-td'>
                        <td style={{ width: "25%" }}>Activity Reservations</td>

                        <td style={{ width: "25%" }}></td>
                      </tr>
                      <tr className='no-border-td'>
                        <td style={{ width: "25%" }}>Amenity or Workspace Bookings</td>

                        <td style={{ width: "25%" }}>Up to 2</td>
                      </tr>
                      <tr className='no-border-td'>
                        <td style={{ width: "25%" }}></td>

                        <td style={{ width: "25%" }}></td>
                      </tr>
                      <tr className='no-border-td'>
                        <td style={{ width: "25%" }}>Global Payments (Requires House Plan+)</td>

                        <td style={{ width: "25%" }}></td>
                      </tr>
                      <tr className='no-border-td'>
                        <td style={{ width: "25%" }}>Hotel Booking Sync (Requires house Plan+)</td>

                        <td style={{ width: "25%" }}></td>
                      </tr>
                      <tr className='no-border-td-end'>
                        <td style={{ width: "25%" }}>Branded Residences (Requires House Plan+)</td>

                        <td style={{ width: "25%" }}></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </section>
            </section>
          )}
          {activeSlide === 2 && (
            <section className='house-table'>
              <section style={{ backgroundColor: "#FFFFFF" }}>
                <div className='table-container'>
                  <table>
                    <thead>
                      <tr>
                        {/* <th
                          colSpan={1}
                          style={{ borderLeft: "none", borderRight: "none", borderTop: "none", paddingBottom: "40px" }}
                        >
                          <h2> FEATURES</h2>
                        </th> */}

                        <th
                          colSpan={2}
                          style={{ borderLeft: "none", borderRight: "none", borderTop: "none", paddingBottom: "40px" }}
                        >
                          <h2>HOUSE</h2>
                          <span className='price-table-plan'><a className="contact-sales-table" href="mailto:sales@peoplevine.com?subject=House%20Plan%20Pricing%20Inquiry">
                            {isAnnual ? "CONTACT SALES" : "CONTACT SALES"}</a>
                            <span style={{ textTransform: "none" }} className='pricing-head-plan__price-period'>
                              {isAnnual ? "" : ""}
                            </span>
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td style={{ width: "25%" }}>CRM w/Unlimited Contacts & Users</td>
                        <td style={{ width: "25%" }}>
                          <Checkmark />
                        </td>
                      </tr>

                      <tr>
                        <td style={{ width: "25%" }}>Signup Form w/Card on file Authorization</td>
                        <td style={{ width: "25%" }}>
                          <Checkmark />
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "25%" }}>Email/Newsletter, Marketing Automations, SMS</td>
                        <td style={{ width: "25%" }}>
                          <Checkmark />
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "25%" }}>Member & Guest Check-in App</td>
                        <td style={{ width: "25%" }}>
                          <Checkmark />
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "25%" }}>Digital ID Card</td>
                        <td style={{ width: "25%" }}>
                          <Checkmark />
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "25%" }}>Close POS Check to card On File</td>
                        <td style={{ width: "25%" }}>
                          <Checkmark />
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "25%" }}>Membership Application</td>
                        <td style={{ width: "25%" }}>
                          <Checkmark />
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "25%" }}>
                          My Account, Profile & Pref, Digital Wallet, House Credits & Payments, Billing & Statements,
                          Invoices, Digital ID Card
                        </td>
                        <td style={{ width: "25%" }}>
                          <Checkmark />
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "25%" }}>Membership Perks</td>
                        <td style={{ width: "25%" }}>
                          <Checkmark />
                        </td>
                      </tr>

                      <tr>
                        <td style={{ width: "25%" }}>Dinning Reservations</td>

                        <td style={{ width: "25%" }}>
                          <Checkmark />
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "25%" }}>Events & Ticketing</td>

                        <td style={{ width: "25%" }}>
                          <Checkmark />
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "25%" }}>In-App Messaging</td>

                        <td style={{ width: "25%" }}>
                          <Checkmark />
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "25%" }}>Staff Directory</td>

                        <td style={{ width: "25%" }}>
                          <Checkmark />
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "25%" }}>Dedicated Account Manager</td>

                        <td style={{ width: "25%" }}>
                          <Checkmark />
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "25%" }}></td>

                        <td style={{ width: "25%" }}></td>
                      </tr>
                      <tr>
                        <td style={{ width: "25%" }}></td>

                        <td style={{ width: "25%" }}></td>
                      </tr>
                      <tr>
                        <td style={{ width: "25%", backgroundColor: "#57806E33" }}>
                          <b>EXPERIENCE SPECIALTY MODULES</b>
                        </td>
                        <td style={{ width: "25%", backgroundColor: "#57806E33" }}></td>
                      </tr>

                      <tr className='no-border-td'>
                        <td style={{ width: "25%" }}>Appointment Bookings</td>

                        <td style={{ width: "25%" }}></td>
                      </tr>
                      <tr className='no-border-td'>
                        <td style={{ width: "25%" }}>Spa & Wellness Bookings</td>

                        <td style={{ width: "25%" }}></td>
                      </tr>
                      <tr className='no-border-td'>
                        <td style={{ width: "25%" }}>Activity Reservations</td>

                        <td style={{ width: "25%" }}></td>
                      </tr>
                      <tr className='no-border-td'>
                        <td style={{ width: "25%" }}>Amenity or Workspace Bookings</td>

                        <td style={{ width: "25%" }}>Up to 4</td>
                      </tr>
                      <tr className='no-border-td'>
                        <td style={{ width: "25%" }}></td>

                        <td style={{ width: "25%" }}></td>
                      </tr>
                      <tr className='no-border-td'>
                        <td style={{ width: "25%" }}>Global Payments (Requires House Plan+)</td>

                        <td style={{ width: "25%" }}></td>
                      </tr>
                      <tr className='no-border-td'>
                        <td style={{ width: "25%" }}>Hotel Booking Sync (Requires house Plan+)</td>

                        <td style={{ width: "25%" }}></td>
                      </tr>
                      <tr className='no-border-td-end'>
                        <td style={{ width: "25%" }}>Branded Residences (Requires House Plan+)</td>

                        <td style={{ width: "25%" }}></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </section>
            </section>
          )}
        </div>
      </section>
    </>
  )
}

export default Pricing
